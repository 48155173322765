<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("feedwall.feedwall_delete_title") }}
      </h1>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered" v-if="thread">
        {{ $t("feedwall.feedwall_delete_thread_description") }}
      </div>
      <div class="size-6 subtitle has-text-centered" v-else-if="comment">
        {{ $t("feedwall.feedwall_delete_comment_description") }}
      </div>

      <div class="is-flex is-flex-direction-row mt-1">
        <button class="button is-light" @click="$parent.close">
          {{ $t("common.cancel") }}
        </button>
        <button class="button is-danger" @click="onDeleteThreadClick" v-if="thread">
          {{ $t("feedwall.feedwall_delete_post") }}
        </button>
        <button class="button is-danger" @click="onDeleteCommentClick" v-else-if="comment">
          {{ $t("feedwall.feedwall_delete_comment") }}
        </button>
      </div>

      <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
        <div class="has-text-centered">
          {{ error | pluckError }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {pluckErrorCode} from "@/shared/utils";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";

export default {
  name: "FeedWallDeleteConfirmationModal",

  mixins: [RequestMixin],

  props: {
    channelId: {
      type: Number,
      required: true,
    },

    thread: {
      type: Object,
    },

    comment: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters("currentUser", {
      currentUserUuid: "uuid",
    }),
    ...mapGetters("auth", ["isAdmin"]),

    userUuid() {
      return this.comment ? this.comment.user.user_identity_token : this.thread.user.user_identity_token;
    },

    isFromCurrentUser() {
      return this.userUuid === this.currentUserUuid;
    },
  },

  requests: {
    ...mapActions("feedWallThread", ["deleteComment"]),
    ...mapActions("feedWallChannel", ["deleteThread"]),
  },

  methods: {
    async onDeleteThreadClick() {
      this.deleteThread({channelId: this.channelId, threadId: this.thread.id, isAdmin: this.isAdmin, isFromCurrentUser: this.isFromCurrentUser})
        .then(result => this.$parent.close());
    },

    onDeleteCommentClick() {
      this.deleteComment({
        channelId: this.channelId,
        threadId: this.comment.feed_wall_thread_id,
        commentId: this.comment.id,
        isAdmin: this.isAdmin,
        isFromCurrentUser: this.isFromCurrentUser,
      }).then(result => this.$parent.close());
    },
  },

  watch: {
    error(newVal) {
      const errorCode = pluckErrorCode(newVal);
      if (errorCode === Constants.NOT_AUTHORIZED) {
        const title = this.$t("roles.not_allowed_dialog_title");
        const message = this.$t("roles.not_allowed_dialog_desc");
        this.$root.openInfoModal({ title, message });
      }
    }
  },
};
</script>

<style scoped></style>
