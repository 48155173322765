<template>
  <a @click="openChannel" class="box fw-channel-list-item" :class="isSmallClass">
    <div class="columns is-mobile is-vcentered is-gapless has-fullwidth">
      <!--      <div class="column is-narrow">-->
      <!--        <div class="quiz-icon-container" :class="isSmallClass">-->
      <!--          <component :is="quizIcon" class="has-fill-primary quiz-icon"></component>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="column">
        <div class="fw-channel-title mr-1" :class="isSmallClass">
          {{ title }}
        </div>
      </div>
      <div class="column is-narrow">
        <div class="is-flex is-justify-content-center is-align-content-center">
          <uncompleted-icon class="fw-channel-icon"></uncompleted-icon>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import UncompletedIcon from "@/assets/quizzes/nav_uncompleted.svg";

export default {
  name: "FeedWallChannel",

  components: {UncompletedIcon},

  props: {
    channel: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      return this.channel.name;
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    openChannel() {
      this.$emit("open-channel", this.channel.id);
    },
  },
};
</script>

<style scoped></style>
