<template>
  <div class="side-menu-container" v-if="isVisible">
    <transition-group class="columns is-mobile is-gapless bubbles-container mb-0" name="list" tag="div">
      <template v-if="maxOpenBubbles > 1">
        <div class="column is-narrow is-flex" v-for="bubble in visibleBubbles" :key="bubble.id">
          <component
            class="mt-auto"
            :is="bubbleComponent(bubble.type)"
            v-bind="bubbleBindings(bubble)"
            :ref="`bubble-${bubble.id}`"
            :is-active="activeBubbleId === bubble.id"
            @active="setActiveBubble"
            @inactive="setInActiveBubble"
            @close="closeBubble"
            @minimize="minimizeBubble"
            @inbox-thread-selected="closeSimilarInboxBubbles"
          ></component>
        </div>
      </template>

      <template v-else-if="visibleBubbles.length === 1">
        <div class="column is-flex has-fullwidth" v-for="bubble in visibleBubbles" :key="bubble.id">
          <component
            :is="bubbleComponent(bubble.type)"
            v-bind="bubbleBindings(bubble)"
            :ref="`bubble-${bubble.id}`"
            :is-active="activeBubbleId === bubble.id"
            @active="setActiveBubble"
            @inactive="setInActiveBubble"
            @close="closeBubble"
            @minimize="minimizeBubble"
            @inbox-thread-selected="closeSimilarInboxBubbles"
          ></component>
        </div>
      </template>
      <template v-else>
        <div class="column is-flex-grow-1"></div>
      </template>
    </transition-group>
    <div class="is-flex is-flex-shrink-1">
      <div class="mt-auto is-flex is-flex-direction-column is-justify-content-center">
        <transition name="fade" mode="out-in">
          <div v-if="isOpened" class="mb-1 mx-auto" key="newBubble">
            <action-overlay-view bottom="0%" right="0%" v-if="sessionDiscussionBubbleVisible">
              <template>
                <b-tooltip
                  class="side-menu-bubble-tooltip-container"
                  :label="sessionDiscussionBubbleTooltip"
                  :delay="500"
                  position="is-left"
                >
                  <div class="side-menu-bubble is-small" @click="openAgendaSessionQuestionsBubble">
                    <img class="svg-icon" v-if="sessionDiscussionBubbleIcon" :src="sessionDiscussionBubbleIcon"/>
                    <agenda-session-questions-icon class="has-fill-primary svg-icon"
                                                   v-else></agenda-session-questions-icon>
                  </div>
                </b-tooltip>
              </template>
            </action-overlay-view>

            <action-overlay-view bottom="0%" right="0%" v-if="inboxBubbleVisible">
              <template>
                <b-tooltip class="side-menu-bubble-tooltip-container" :label="inboxBubbleTooltip" :delay="500"
                           position="is-left">
                  <div class="side-menu-bubble is-small side-menu-bubble-inbox" @click="openInboxBubble">
                    <img class="svg-icon" v-if="inboxBubbleIcon" :src="inboxBubbleIcon"/>
                    <inbox-icon class="has-fill-primary svg-icon" v-else></inbox-icon>
                  </div>
                </b-tooltip>
              </template>

              <template #overlay>
                <div v-if="unreadMessagesCount" class="side-menu-unread-indicator is-small">
                  <div class="side-menu-unread-text">{{ unreadMessagesCount > 9 ? "+" : unreadMessagesCount }}</div>
                </div>
              </template>
            </action-overlay-view>

            <action-overlay-view bottom="0%" right="0%" v-if="feedWallBubbleVisible">
              <template>
                <b-tooltip class="side-menu-bubble-tooltip-container" :label="feedWallBubbleTooltip" :delay="500"
                           position="is-left">
                  <div class="side-menu-bubble is-small" @click="openFeedWallBubble">
                    <img class="svg-icon" v-if="feedWallBubbleIcon" :src="feedWallBubbleIcon"/>
                    <feed-wall-icon class="has-fill-primary svg-icon" v-else></feed-wall-icon>
                  </div>
                </b-tooltip>
              </template>
            </action-overlay-view>

            <action-overlay-view bottom="0%" right="0%" v-if="quizBubbleVisible">
              <template>
                <b-tooltip class="side-menu-bubble-tooltip-container" :label="quizBubbleTooltip" :delay="500"
                           position="is-left">
                  <div class="side-menu-bubble is-small" @click="openQuizzesBubble">
                    <img class="svg-icon" v-if="quizBubbleIcon" :src="quizBubbleIcon"/>
                    <quiz-icon class="has-fill-primary svg-icon" v-else></quiz-icon>
                  </div>
                </b-tooltip>
              </template>

              <template #overlay>
                <div v-if="uncompletedQuizzesCount" class="side-menu-unread-indicator is-small">
                  <div class="side-menu-unread-text">{{
                      uncompletedQuizzesCount > 9 ? "+" : uncompletedQuizzesCount
                    }}
                  </div>
                </div>
              </template>
            </action-overlay-view>
          </div>

          <div v-else-if="minimizedBubbles.length" class="mb-1 mx-auto" key="minimizedBubbles">
            <template v-for="bubble in minimizedBubbles">
              <minimized-bubble
                :bubble="bubble"
                @close="closeBubble"
                @maximize="maximizeBubble"
                :key="bubble.id"
                :current-user-uuid="currentUserUuid"
              ></minimized-bubble>
            </template>
          </div>
        </transition>

        <action-overlay-view bottom="0%" right="0%">
          <template>
            <div class="side-menu-bubble" @click="toggleMenu">
              <transition name="fade" mode="out-in">
                <close-icon class="side-menu-bubble-icon svg-icon side-menu-icon-temporary" key="closeIcon"
                            v-if="isOpened"></close-icon>
                <menu-icon class="side-menu-bubble-icon svg-icon side-menu-icon-temporary" key="menuIcon"
                           v-else></menu-icon>
              </transition>
            </div>
          </template>

          <template #overlay>
            <transition name="fade-longer">
              <div v-if="mainUnreadIndicatorVisible" class="side-menu-unread-indicator"></div>
            </transition>
          </template>
        </action-overlay-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import CloseIcon from "@/assets/icon_close.svg";
import MenuIcon from "@/assets/icons/menu.svg";
import QuizIcon from "@/assets/icons/menu-quiz.svg";
import InboxIcon from "@/assets/navbar/message-icon.svg";
import FeedWallIcon from "@/assets/icons/menu-feed-wall.svg";
import AgendaSessionQuestionsIcon from "@/assets/icons/rupor.svg";
import ActionOverlayView from "@/web/components/shared/ActionOverlayView";
import QuizBubble from "@/web/components/sidemenu/bubbles/QuizBubble";
import FeedWallBubble from "@/web/components/sidemenu/bubbles/FeedWallBubble";
import InboxBubble from "@/web/components/sidemenu/bubbles/InboxBubble";
import AgendaSessionQuestionsBubble from "@/web/components/sidemenu/bubbles/AgendaSessionQuestionsBubble";
import MinimizedBubble from "@/web/components/sidemenu/MinimizedBubble";
import Constants from "@/web/constants";
import { throttle } from "@/shared/utils";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "SideMenuBubbles",
  mixins: [LpConfigMixin],
  components: {
    MinimizedBubble,
    ActionOverlayView,
    MenuIcon,
    QuizIcon,
    InboxIcon,
    FeedWallIcon,
    CloseIcon,
    AgendaSessionQuestionsIcon,
  },

  data() {
    return {
      lastBubbleId: -1,
      isOpened: false,
      contextMenuOpen: false,
      visibleBubbles: [],
      minimizedBubbles: [],
      maxOpenBubbles: 2,
      activeBubbleId: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    ...mapMutations("sideMenu", ["bubbleOpened"]),
    ...mapActions("feedWall", ["deleteChannel", "loadChannels"]),
    ...mapActions("feedWallChannel", ["deleteLocalThread", "updateThreads"]),
    ...mapActions("feedWallThread", ["deleteLocalComment", "updateComments"]),

    openMenu() {
      this.isOpened = true;
    },

    closeMenu() {
      this.isOpened = false;
    },

    toggleMenu() {
      this.isOpened = !this.isOpened;
    },

    setActiveBubble(bubbleId) {
      if (this.activeBubbleId !== bubbleId) {
        this.activeBubbleId = bubbleId;
      }
    },

    setInActiveBubble(bubbleId) {
      if (this.activeBubbleId === bubbleId) {
        this.activeBubbleId = null;
      }
    },

    openInboxBubble() {
      const minimizedBubble = this.findMinimizedInboxBubble(null);
      if (minimizedBubble) {
        this.maximizeBubble(minimizedBubble);
      } else {
        const maximizedBubble = this.findMaximizedInboxBubble(null);
        if (!maximizedBubble) {
          this.lastBubbleId = this.lastBubbleId + 1;
          const inboxBubble = {
            id: this.lastBubbleId,
            type: Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES,
            isFresh: true,
          };
          this.openBubble(inboxBubble);
        }
      }
      this.closeMenu();
    },

    findMinimizedInboxBubble(inboxThreadId) {
      if (inboxThreadId) {
        return this.minimizedBubbles.find(
          bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES && bubble.inboxThreadId === inboxThreadId,
        );
      } else {
        return this.minimizedBubbles.find(
          bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES && bubble.inboxThreadId == null,
        );
      }
    },

    findMaximizedInboxBubble(inboxThreadId) {
      if (inboxThreadId) {
        return this.visibleBubbles.find(bubble => {
          const updatedBubble = this.getUpdatedBubble(bubble.id);
          return updatedBubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES && updatedBubble.inboxThreadId === inboxThreadId;
        });
      } else {
        return this.visibleBubbles.find(bubble => {
          const updatedBubble = this.getUpdatedBubble(bubble.id);
          return updatedBubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES && updatedBubble.inboxThreadId == null;
        });
      }
    },

    closeSimilarInboxBubbles(inboxBubble) {
      this.minimizedBubbles = this.minimizedBubbles.filter(
        bubble =>
          bubble.type !== Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES ||
          (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES && bubble.inboxThreadId !== inboxBubble.inboxThreadId),
      );
      this.visibleBubbles = this.visibleBubbles.filter(bubble => {
        const updatedBubble = this.getUpdatedBubble(bubble.id);
        return (
          inboxBubble.id === updatedBubble.id ||
          updatedBubble.type !== Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES ||
          (updatedBubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES &&
            updatedBubble.inboxThreadId !== inboxBubble.inboxThreadId)
        );
      });
    },

    openQuizzesBubble() {
      const minimizedBubble = this.minimizedBubbles.find(bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE);
      if (minimizedBubble) {
        this.maximizeBubble(minimizedBubble);
      } else {
        const visibleBubble = this.visibleBubbles.find(bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE);
        if (!visibleBubble) {
          this.lastBubbleId = this.lastBubbleId + 1;
          const quizBubble = {
            id: this.lastBubbleId,
            type: Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE,
            isFresh: true,
          };
          this.openBubble(quizBubble);
        }
      }
      this.closeMenu();
    },

    openAgendaSessionQuestionsBubble() {
      const minimizedBubble = this.minimizedBubbles.find(
        bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS,
      );
      if (minimizedBubble) {
        this.maximizeBubble(minimizedBubble);
      } else {
        const visibleBubble = this.visibleBubbles.find(bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS);
        if (!visibleBubble) {
          this.lastBubbleId = this.lastBubbleId + 1;
          const quizBubble = {
            id: this.lastBubbleId,
            type: Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS,
            isFresh: true,
          };
          this.openBubble(quizBubble);
        }
      }
      this.closeMenu();
    },

    closeSimilarAgendaQuestionBubbles(agendaQuestionBubble) {
      this.minimizedBubbles = this.minimizedBubbles.filter(
        bubble => bubble.type !== Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS,
      );
      this.visibleBubbles = this.visibleBubbles.filter(bubble => bubble.type !== Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS);
    },

    openFeedWallBubble() {
      const minimizedBubble = this.findMinimizedFeedWallBubble(null);
      if (minimizedBubble) {
        this.maximizeBubble(minimizedBubble);
      } else {
        const maximizedBubble = this.findMaximizedFeedWallBubble(null);
        if (!maximizedBubble) {
          this.lastBubbleId = this.lastBubbleId + 1;
          const feedWallBubble = {
            id: this.lastBubbleId,
            type: Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE,
            isFresh: true,
          };
          this.openBubble(feedWallBubble);
        }
      }
      this.closeMenu();
    },

    findMinimizedFeedWallBubble(feedWallChannelId) {
      if (feedWallChannelId) {
        return this.minimizedBubbles.find(
          bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE && bubble.feedWallChannelId === feedWallChannelId,
        );
      } else {
        return this.minimizedBubbles.find(
          bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE && bubble.feedWallChannelId == null,
        );
      }
    },

    findMaximizedFeedWallBubble(feedWallChannelId) {
      if (feedWallChannelId) {
        return this.visibleBubbles.find(bubble => {
          const updatedBubble = this.getUpdatedBubble(bubble.id);
          return (
            updatedBubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE &&
            updatedBubble.feedWallChannelId === feedWallChannelId
          );
        });
      } else {
        return this.visibleBubbles.find(bubble => {
          const updatedBubble = this.getUpdatedBubble(bubble.id);
          return updatedBubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE && updatedBubble.feedWallChannelId == null;
        });
      }
    },

    closeBubble(bubble) {
      this.visibleBubbles = this.visibleBubbles.filter(visibleBubble => visibleBubble.id !== bubble.id);
      this.minimizedBubbles = this.minimizedBubbles.filter(visibleBubble => visibleBubble.id !== bubble.id);
    },

    openBubble(bubble) {
      if (this.visibleBubbles.length < this.maxOpenBubbles) {
        this.visibleBubbles = [bubble, ...this.visibleBubbles];
      } else if (this.maxOpenBubbles > 0) {
        let bubbleToMinimize = this.visibleBubbles[this.visibleBubbles.length - 1];
        let updatedBubbleToMinimize = this.$refs[`bubble-${bubbleToMinimize.id}`][0].getBubbleToMinimize();

        this.minimizedBubbles = [...this.minimizedBubbles, updatedBubbleToMinimize || bubbleToMinimize];
        this.visibleBubbles = [...this.visibleBubbles.slice(0, this.visibleBubbles.length - 1)];
        setTimeout(() => {
          this.visibleBubbles = [bubble, ...this.visibleBubbles];
        }, 0);
      }
    },

    minimizeBubble(bubble) {
      this.visibleBubbles = this.visibleBubbles.filter(visibleBubble => visibleBubble.id !== bubble.id);
      this.closeMinimizedSimilarBubbles(bubble);
      this.minimizedBubbles = [...this.minimizedBubbles, bubble];
    },

    closeMinimizedSimilarBubbles(bubble) {
      if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
        this.minimizedBubbles = this.minimizedBubbles.filter(
          minimizedBubble => minimizedBubble.type !== bubble.type || minimizedBubble.inboxThreadId !== bubble.inboxThreadId,
        );
      } else if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) {
        this.minimizedBubbles = this.minimizedBubbles.filter(
          minimizedBubble =>
            minimizedBubble.feedWallChannelId !== bubble.feedWallChannelId ||
            (minimizedBubble.feedWallChannelId === bubble.feedWallChannelId && minimizedBubble.feedWallThreadId !== bubble.feedWallThreadId),
        );
      } else if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
        this.minimizedBubbles = this.minimizedBubbles.filter(minimizedBubble => minimizedBubble.agendaSessionId !== bubble.agendaSessionId);
      }
    },

    maximizeBubble(bubble) {
      this.minimizedBubbles = this.minimizedBubbles.filter(minimizedBubble => minimizedBubble.id !== bubble.id);
      this.openBubble(bubble);
    },

    getUpdatedBubble(bubbleId) {
      return this.$refs[`bubble-${bubbleId}`][0].getBubbleToMinimize();
    },

    onResize: throttle(function(newVal) {
      this.maxOpenBubbles = Math.max(1, Math.floor((window.innerWidth - SPACING) / BUBBLE_WIDTH));
    }, 80),

    bubbleComponent(type) {
      if (type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
        return InboxBubble;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) {
        return QuizBubble;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) {
        return FeedWallBubble;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
        return AgendaSessionQuestionsBubble;
      } else {
        throw Error("Invalid side menu bubble type");
      }
    },

    bubbleBindings(bubble) {
      const commonBindings = {
        bubble: bubble,
      };
      if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
        return {
          ...commonBindings,
        };
      } else if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) {
        return {
          ...commonBindings,
          quizModules: this.quizModules,
        };
      } else if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) {
        return {
          ...commonBindings,
        };
      } else if (bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
        return {
          ...commonBindings,
        };
      } else {
        throw Error("Invalid side menu bubble type");
      }
    },

    getCustomBubbleTooltip(type) {
      return this.getCustomTranslation(this.sideMenuBubblesTooltips, type, null);
    },

    getCustomBubbleIcon(type) {
      return this.getCustomTranslation(this.sideMenuBubblesIcons, type, null);
    },
  },

  computed: {
    ...mapGetters(["inboxEnabled", "quizModules", "feedWallModules"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesTooltips", "sideMenuBubblesIcons"]),
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),
    ...mapGetters("inbox", ["unreadMessagesCount"]),
    ...mapGetters("quizzes", ["uncompletedQuizzesCount"]),
    ...mapGetters("agendaSessionQuestions", ["isSessionDiscussionEnabled"]),
    ...mapState("sideMenu", ["bubbleToOpen", "feedWallUpdatePayload"]),

    isVisible() {
      return !SIDE_MENU_RESTRICTED_ROUTES.includes(this.$route.name) && this.isAnySubBubbleVisible;
    },

    isAnySubBubbleVisible() {
      return this.inboxBubbleVisible || this.quizBubbleVisible || this.feedWallBubbleVisible || this.sessionDiscussionBubbleVisible;
    },

    inboxBubbleVisible() {
      return this.inboxEnabled;
    },

    quizBubbleVisible() {
      return this.quizModules.length;
    },

    feedWallBubbleVisible() {
      return this.feedWallModules.length;
    },

    sessionDiscussionBubbleVisible() {
      return this.isSessionDiscussionEnabled;
    },

    mainUnreadIndicatorVisible() {
      return !this.isOpened && (this.unreadMessagesCount || this.uncompletedQuizzesCount);
    },

    inboxBubbleTooltip() {
      return this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) || this.$t("inbox.inbox");
    },

    feedWallBubbleTooltip() {
      return this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) || this.$t("feedwall.feedwall_title");
    },

    sessionDiscussionBubbleTooltip() {
      return (
        this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) ||
        this.$t("session_questions.session_questions_title")
      );
    },

    quizBubbleTooltip() {
      return this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) || this.$t("quizzes.quizzes_and_surveys");
    },

    inboxBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES);
    },

    feedWallBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE);
    },

    sessionDiscussionBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS);
    },

    quizBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE);
    },
  },

  watch: {
    maxOpenBubbles(newVal) {
      if (this.visibleBubbles.length > newVal) {
        if (newVal === 0) {
          const updatedBubbles = this.visibleBubbles.map(bubble => this.$refs[`bubble-${bubble.id}`][0].getBubbleToMinimize());
          this.minimizedBubbles = [...this.minimizedBubbles, ...updatedBubbles];
          this.visibleBubbles = [];
        } else {
          const bubblesToMinimize = this.visibleBubbles.length - newVal;
          const bubbles = this.visibleBubbles.slice(0, bubblesToMinimize);
          const updatedBubbles = bubbles.map(bubble => this.$refs[`bubble-${bubble.id}`][0].getBubbleToMinimize());
          this.visibleBubbles = this.visibleBubbles.slice(bubblesToMinimize);
          this.minimizedBubbles = [...this.minimizedBubbles, ...updatedBubbles];
        }
      }
    },

    minimizedBubbles(newVal) {
      if (newVal.length > MAX_MINIMIZED_BUBBLES) {
        const bubblesToRemove = newVal.length - MAX_MINIMIZED_BUBBLES;
        this.minimizedBubbles = this.minimizedBubbles.slice(bubblesToRemove);
      }
    },

    bubbleToOpen(newVal) {
      if (newVal) {
        this.lastBubbleId = this.lastBubbleId + 1;
        newVal.id = this.lastBubbleId;
        if (newVal.type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
          this.closeSimilarAgendaQuestionBubbles(newVal);
        } else if (newVal.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
          this.closeSimilarInboxBubbles(newVal);
        }
        this.openBubble(newVal);
        this.bubbleOpened();
      }
    },

    feedWallUpdatePayload(newVal) {
      if (newVal) {
        const visibleFeedWallBubbles = this.visibleBubbles
          .filter(bubble => bubble.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE)
          .map(bubble => this.$refs[`bubble-${bubble.id}`][0].getBubbleToMinimize());
        const activity = newVal.activity.toLowerCase();
        if (activity.includes(Constants.PUSHER_FEEDWALL)) {
          if (activity.includes(Constants.PUSHER_FEEDWALL_CHANNEL)) {
            //CHANNELS
            if (activity.includes(Constants.PUSHER_DESTROY_ACTION)) {
              this.deleteChannel({ channelId: newVal.channel_id });
            } else {
              if (visibleFeedWallBubbles.length) {
                this.loadChannels({ componentId: newVal.component_id });
              }
            }
          } else if (activity.includes(Constants.PUSHER_FEEDWALL_THREAD)) {
            //THREADS
            if (activity.includes(Constants.PUSHER_DESTROY_ACTION)) {
              this.deleteLocalThread({ threadId: newVal.thread_id });
            } else {
              if (visibleFeedWallBubbles.some(bubble => bubble.feedWallChannelId === newVal.channel_id)) {
                this.updateThreads({ channelId: newVal.channel_id });
              }
            }
          } else if (activity.includes(Constants.PUSHER_FEEDWALL_COMMENT)) {
            //COMMENTS
            if (activity.includes(Constants.PUSHER_DESTROY_ACTION)) {
              this.deleteLocalComment({
                commentId: newVal.comment_id,
                threadId: newVal.thread_id,
              });
            } else {
              if (visibleFeedWallBubbles.some(bubble => bubble.feedWallChannelId === newVal.channel_id)) {
                this.updateComments({
                  channelId: newVal.channel_id,
                  threadId: newVal.thread_id,
                });
              }
            }
          }
        }
      }
    },
  },
};

const SPACING = 100;
const BUBBLE_WIDTH = 420;
const MAX_MINIMIZED_BUBBLES = 5;

const SIDE_MENU_RESTRICTED_ROUTES = [
  Constants.ROUTE_INVALID,
  Constants.ROUTE_ACCESS_CODE,
  Constants.ROUTE_LOGIN,
  Constants.ROUTE_REGISTER,
  Constants.ROUTE_RESET_PASSWORD,
  Constants.ROUTE_FORGOT_PASSWORD,
  Constants.ROUTE_SESSION_DISCUSSION,
  Constants.ROUTE_QUIZ_LEADERBOARD,
  Constants.ROUTE_SURVEY_RESULTS,
  Constants.ROUTE_TREASURE_HUNT_LEADERBOARD,
];
</script>

<style lang="scss" scoped>
.side-menu-icon-temporary {
  width: 40px;
  height: 40px;
  margin: auto;
}
</style>
