<template>
  <div class="modal-card">
    <section class="modal-card-head modal-card-body modal-card-foot has-text-centered">
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <div class="modal-close-button" @click="$parent.close()">
          <close-icon class="close-icon"></close-icon>
        </div>

        <div class="modal-card-title">{{ $t("navbar.help_organizer_email_title") }}</div>
        <div class="modal-card-subtitle mt-2">{{ description }}</div>

        <contact-link class="mt-1" type="email" :target="eventEmail"></contact-link>
        <a class="button is-primary mt-2 mx-0" :href="eventEmailHref">{{ $t("navbar.help_organizer_email_button") }}</a>
        <div class="modal-card-subtitle is-size-8 mt-1">{{ $t("navbar.help_organizer_email_button_description") }}</div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ContactLink from "@/web/components/shared/ContactLink";
import CloseIcon from "@/assets/icon_close.svg";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "NavOrganizerEmail",
  mixins: [LpConfigMixin],
  components: { ContactLink, CloseIcon },

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["helpModule"]),

    eventEmail() {
      return this.event.email;
    },

    eventEmailHref() {
      return `mailto:${this.eventEmail}`;
    },

    description() {
      return this.getCustomTranslation(
        this.helpModule,
        LpConfigConstants.HELP_FIELDS.FEEDBACK_DESCRIPTION,
        this.$t("navbar.help_organizer_email_description"),
      );
    },
  },
};
</script>

<style scoped></style>
