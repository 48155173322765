<template>
  <bubble-box
    ref="bubbleBox"
    :title="bubbleTitle"
    :is-full-height="false"
    :back-button-visible="backButtonVisible"
    :v-chat-enabled="!!selectedThreadId"
    :is-initialized="isInitialized"
    :top-scroll-listener-enabled="true"
    :bubble-id="bubble.id"
    :is-active="isActive"
    content-padding="0px"
    :init-scroll-direction="initScrollDirection"
    :is-panel="isPanel"
    @minimize="minimize"
    @close="$emit('close', bubble)"
    @navigate-back="navigateBack"
    @bottom-of-scroll="loadInboxThreads"
    @top-of-scroll="onTopOfScrollReached"
    @active="setAsActiveBubble"
    @inactive="setAsInActiveBubble"
  >
    <template #fixed-header v-if="selectedThreadId">
      <inbox-thread-banner :user="threadUser" :is-small="true" v-prevent-parent-scroll></inbox-thread-banner>
    </template>
    <template #fixed-header v-else-if="createThreadMode">
      <inbox-create-thread
        ref="inboxCreateThread"
        :user-uuid="selectedUserUuid"
        :is-small="true"
        @thread-selected="onThreadSelected"
      ></inbox-create-thread>
    </template>
    <template #fixed-header v-else>
      <div v-prevent-parent-scroll class="inbox-search-container columns is-mobile is-vcentered is-variable is-marginless is-1 px-2 pb-0 mb-0">
        <div class="column">
          <search-input
            :placeholder="$t('inbox.threads_search_placeholder')"
            v-model="query"
            border-radius="8px"
            :button-visible="false"
            :is-small="true"
          ></search-input>
        </div>
        <div class="column is-narrow">
          <a class="button new-thread-button is-primary" @click="launchCreateThreadMode">
            <add-icon class="add-icon-color"></add-icon>
          </a>
        </div>
      </div>
    </template>

    <template v-if="selectedThreadId">
      <inbox-bubble-messages
        ref="inboxMessages"
        :thread-id="selectedThreadId"
        :is-sending="isSending"
        :is-active="isActive"
      ></inbox-bubble-messages>
    </template>
    <template v-else-if="createThreadMode"></template>
    <template v-else>
      <div v-if="inboxThreads.length">
        <inbox-bubble-contact-list
          :threads="inboxThreads"
          :current-user-uuid="currentUserUuid"
          :is-small="true"
          @thread-selected="onThreadSelected"
        ></inbox-bubble-contact-list>
      </div>
      <div class="is-relative placeholder-container" v-else-if="isLoading">
        <b-loading :is-full-page="false" :active="true"></b-loading>
      </div>
      <div class="placeholder-container has-text-centered has-text-secondary" v-else>
        <div class="my-auto">
          {{ $t("inbox.empty_threads_placeholder") }}
        </div>
      </div>
    </template>

    <template #fixed-footer v-if="selectedThreadId">
      <inbox-text-input
        v-prevent-parent-scroll
        v-if="!isDeletedAccount"
        class="p-1"
        :is-small="true"
        v-on:send="sendMessage"
        @refresh-layout="refreshBubbleLayout"
      ></inbox-text-input>
    </template>
    <template #fixed-footer v-else></template>
  </bubble-box>
</template>

<script>
import BubbleBox from "@/web/components/sidemenu/BubbleBox";
import SearchInput from "@/shared/components/SearchInput";
import AddIcon from "@/assets/icons/add.svg";
import InboxBubbleContactList from "@/web/components/inbox/InboxBubbleContactList";
import Constants from "@/web/constants";
import { mapActions, mapGetters, mapState } from "vuex";
import InboxBubbleMessages from "@/web/components/inbox/InboxBubbleMessages";
import InboxThreadBanner from "@/web/components/inbox/InboxThreadBanner";
import InboxTextInput from "@/web/components/inbox/InboxTextInput";
import InboxCreateThread from "@/web/components/inbox/InboxCreateThread";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "InboxBubble",
  mixins: [LpConfigMixin],
  components: {
    InboxCreateThread,
    InboxThreadBanner,
    BubbleBox,
    InboxBubbleContactList,
    InboxBubbleMessages,
    InboxTextInput,
    SearchInput,
    AddIcon,
  },

  props: {
    bubble: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    isPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedThreadId: null,
      selectedUserUuid: null,
      createThreadMode: false,
      query: "",
      isSending: false,
    };
  },

  watch: {
    bubble: {
      immediate: true,
      handler: function (newValue) {
        if (newValue.inboxThreadId) {
          this.selectedThreadId = newValue.inboxThreadId;
        } else if (newValue.inboxUserUuid) {
          this.selectedUserUuid = newValue.inboxUserUuid;
          this.createThreadMode = true;
        }
      },
    },
  },

  computed: {
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),
    ...mapGetters("inboxThreads", ["getThreads", "threadById", "isLoading"]),
    ...mapGetters("inboxMessages", ["getLastMessageTimestamp"]),
    ...mapState(["eventId"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesTitles"]),

    Constants: () => Constants,

    bubbleId() {
      return this.bubble.id;
    },

    inboxThreads() {
      return this.getThreads(this.query, this.currentUserUuid);
    },

    thread() {
      return this.threadById(this.selectedThreadId);
    },

    threadUser() {
      return this.thread.users.find(user => user.user_identity_token !== this.currentUserUuid) || this.deletedUserPlaceholder;
    },

    isDeletedAccount() {
      return !this.threadUser.user_identity_token;
    },

    deletedUserPlaceholder() {
      return {
        first_name: " ",
        last_name: " ",
        picture: null,
      };
    },

    isInitialized() {
      if (this.selectedThreadId) {
        return !this.getLastMessageTimestamp(this.selectedThreadId);
      } else {
        return this.inboxThreads.length > 0;
      }
    },

    initScrollDirection() {
      if (this.selectedThreadId) {
        return "bottom";
      } else {
        return "top";
      }
    },

    backButtonVisible() {
      return !!(this.selectedThreadId || this.createThreadMode);
    },

    bubbleTitle() {
      const bubbleType = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE;
      return this.getCustomTranslation(this.sideMenuBubblesTitles, bubbleType, this.$t("inbox.inbox"));
    },
  },

  methods: {
    ...mapActions("inboxThreads", ["loadInboxThreads", "loadInboxThread", "responseToThread"]),

    onThreadSelected(threadId) {
      this.selectedThreadId = threadId;
      this.createThreadMode = false;
      this.$refs.bubbleBox.refreshLayout();
      if (threadId) {
        this.$emit("inbox-thread-selected", this.getBubbleToMinimize());
      }
    },

    getBubbleToMinimize() {
      const commonBubbleFields = {
        id: this.bubble.id,
        type: this.bubble.type,
      };
      if (this.selectedThreadId) {
        return {
          ...commonBubbleFields,
          inboxThreadId: this.selectedThreadId,
          inboxThreadUser: this.threadUser,
        };
      } else if (this.selectedUserUuid) {
        return { ...commonBubbleFields, inboxUserUuid: this.selectedUserUuid };
      } else {
        return commonBubbleFields;
      }
    },

    navigateBack() {
      let navigated = false;
      if (this.selectedThreadId) {
        navigated = true;
        this.selectedThreadId = null;
        this.refreshBubbleLayout();
      } else if (this.createThreadMode) {
        navigated = true;
        this.selectedUserUuid = null;
        this.createThreadMode = false;
        this.refreshBubbleLayout();
      }
      return navigated;
    },

    launchCreateThreadMode() {
      this.createThreadMode = true;
      this.refreshBubbleLayout();
    },

    refreshBubbleLayout() {
      this.$refs.bubbleBox.refreshLayout();
    },

    sendMessage(message) {
      if (message && message.length) {
        this.isSending = true;
        this.responseToThread({ message, threadId: this.selectedThreadId }).finally(() => (this.isSending = false));
      }
    },

    onTopOfScrollReached() {
      if (this.$refs.inboxMessages) {
        this.$refs.inboxMessages.loadNextPage();
      }
    },

    minimize() {
      this.$emit("minimize", this.getBubbleToMinimize());
    },

    setAsActiveBubble() {
      this.$emit("active", this.bubbleId);
    },

    setAsInActiveBubble() {
      this.$emit("inactive", this.bubbleId);
    },
  },
};
</script>

<style scoped>
.inbox-search-container {
  background-color: var(--side-menu-background-color);
}

.placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}

.new-thread-button {
  height: 38px;
  width: 38px;
  padding: 4px;
}

.add-icon-color {
  fill: var(--inbox-add-item-icon-color);
}
</style>
