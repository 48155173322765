<template>
  <div>
    <!--TITLE-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.event_tickets_subtitle") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editSubtitle">
        {{ subtitleLabel }}
      </b-button>
    </div>

    <!--PRETAX PRICE VISIBLE-->
    <b-field class="mt-1">
      <b-switch v-model="$data[moduleFields.PRETAX_PRICE_VISIBLE]">
        {{ $t("admin_panel.event_tickets_pretax_price_visible") }}
      </b-switch>
    </b-field>

    <!--PRETAX PRICE HIGHLIGHTED-->
    <b-field>
      <b-switch v-model="$data[moduleFields.PRETAX_PRICE_HIGHLIGHTED]">
        {{ $t("admin_panel.event_tickets_pretax_price_highlighted") }}
      </b-switch>
    </b-field>

    <!--EQUAL HEIGHTS-->
    <b-field>
      <b-switch v-model="$data[moduleFields.EQUAL_HEIGHTS]">{{ $t("admin_panel.event_tickets_equal_heights") }} </b-switch>
    </b-field>

    <!--TICKET TYPE-->
    <div class="field mt-1">
      <label class="label has-text-primary mb-1">{{
          $t("admin_panel.event_tickets_ticket_type")
        }}</label>
      <dropdown-select-wrapper
        :items="ticketViewTypes"
        v-model="$data[moduleFields.TICKET_VIEW_TYPE]"
      ></dropdown-select-wrapper>
    </div>

    <div class="mt-1">
      <!--TICKET IDS-->
      <label class="label has-text-primary mb-1">{{ $t("admin_panel.event_tickets_event_tickets") }}</label>
      <event-ticket-ids-picker v-model="$data[moduleFields.TICKET_IDS]"></event-ticket-ids-picker>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import EventTicketIdsPicker from "@/web/components/admin/subpanels/modules/views/EventTicketIdsPicker";
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";

export default {
  name: "AdminModuleConfigEventTickets",
  components: {
    DropdownSelectWrapper,
    EventTicketIdsPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  data() {
    const moduleFields = LpConfigConstants.EVENT_TICKETS_MODULE_FIELDS;
    return {
      [moduleFields.SUBTITLE]: this.value[moduleFields.SUBTITLE] ?? null,
      [moduleFields.TICKET_IDS]: this.value[moduleFields.TICKET_IDS] ?? [],
      [moduleFields.PRETAX_PRICE_VISIBLE]: this.value[moduleFields.PRETAX_PRICE_VISIBLE],
      [moduleFields.PRETAX_PRICE_HIGHLIGHTED]: this.value[moduleFields.PRETAX_PRICE_HIGHLIGHTED],
      [moduleFields.EQUAL_HEIGHTS]: this.value[moduleFields.EQUAL_HEIGHTS],
      [moduleFields.TICKET_VIEW_TYPE]: this.value[moduleFields.TICKET_VIEW_TYPE] ?? LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA,
      awaitingFieldForTranslationKey: null,
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.EVENT_TICKETS_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },

    subtitleLabel() {
      return this.$data[this.moduleFields.SUBTITLE] || this.$t("admin_panel.select_translation_key");
    },

    ticketViewTypes() {
      return Object.values(LpConfigConstants.EVENT_TICKET_VIEW_TYPES);
    }
  },

  methods: {
    editSubtitle() {
      this.awaitingFieldForTranslationKey = this.moduleFields.SUBTITLE;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
