<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--PAGE SIZE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.agenda_page_size") }}</label>
        <div class="control">
          <input class="input is-simple" type="number" min="0" step="1"
                 :placeholder="$t('admin_panel.agenda_page_size')"
                 v-model.number="$data[moduleFields.PAGE_SIZE]"/>
        </div>
      </div>

      <!--LP MODE-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.LP_MODE]">{{ $t("admin_panel.agenda_lp_mode") }}</b-switch>
      </b-field>

      <!--HIDE SEARCH-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.HIDE_SEARCH]">{{ $t("admin_panel.agenda_hide_search") }}</b-switch>
      </b-field>

      <!--HIDE DAYS-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.HIDE_DAYS]">{{ $t("admin_panel.agenda_hide_days") }}</b-switch>
      </b-field>

      <!--HIDE SESSION TIME-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.HIDE_SESSION_TIME]">{{ $t("admin_panel.agenda_hide_session_time") }}
        </b-switch>
      </b-field>

      <!--HIDE LIVE STREAM BUTTONS-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.HIDE_LIVE_STREAM_BUTTONS]">
          {{ $t("admin_panel.agenda_hide_live_stream_buttons") }}
        </b-switch>
      </b-field>

      <!--SHOW LIVE INDICATOR-->
      <b-field class="mt-1" v-if="!$data[moduleFields.HIDE_LIVE_STREAM_BUTTONS]">
        <b-switch v-model="$data[moduleFields.SHOW_LIVE_INDICATOR]">{{ $t("admin_panel.agenda_show_live_indicator") }}
        </b-switch>
      </b-field>

      <!--LIVEWEBINAR ONLY FOR AUTHORIZED-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.LIVEWEBINAR_ONLY_FOR_AUTHORIZED]">{{ $t("admin_panel.agenda_livewebinar_only_for_authorized") }}
        </b-switch>
      </b-field>

      <!--TODO: CUSTOM SPONSORS NOT IMPLEMENTED-->

      <!--RATING ALWAYS ENABLED-->
      <div class="mt-1">
        <label class="label has-text-primary mb-1">{{ $t("admin_panel.agenda_rating_always_enabled") }}</label>
        <agenda-component-ids-picker
          v-model="$data[moduleFields.COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED]"></agenda-component-ids-picker>
      </div>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import AgendaComponentIdsPicker from "@/web/components/admin/subpanels/modules/views/AgendaComponentIdsPicker";

export default {
  name: "AdminModuleConfigAgenda",
  components: { AgendaComponentIdsPicker },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.AGENDA_MODULE_FIELDS;
    return {
      [moduleFields.PAGE_SIZE]: this.value[moduleFields.PAGE_SIZE] ?? 6,
      [moduleFields.LP_MODE]: this.value[moduleFields.LP_MODE] ?? true,
      [moduleFields.HIDE_SEARCH]: this.value[moduleFields.HIDE_SEARCH] ?? false,
      [moduleFields.HIDE_DAYS]: this.value[moduleFields.HIDE_DAYS] ?? false,
      [moduleFields.HIDE_SESSION_TIME]: this.value[moduleFields.HIDE_SESSION_TIME] ?? false,
      [moduleFields.LIVEWEBINAR_ONLY_FOR_AUTHORIZED]: this.value[moduleFields.LIVEWEBINAR_ONLY_FOR_AUTHORIZED] ?? false,
      [moduleFields.SHOW_LIVE_INDICATOR]: this.value[moduleFields.SHOW_LIVE_INDICATOR] ?? false,
      [moduleFields.HIDE_LIVE_STREAM_BUTTONS]: this.value[moduleFields.HIDE_LIVE_STREAM_BUTTONS] ?? false,
      [moduleFields.CUSTOM_SPONSORS]: this.value[moduleFields.CUSTOM_SPONSORS],
      [moduleFields.COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED]: this.value[moduleFields.COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED] || [],
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.AGENDA_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
