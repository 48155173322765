<template>
  <b-dropdown position="is-bottom-left" ref="localesDropdown" aria-role="menu" trap-focus can-close close-on-click>
    <b-button type="is-text" class="is-navbar-item is-borderless pl-1 pr-1" slot="trigger">
      <div class="navbar-icon-container">
        <help-icon class="navbar-icon navbar-icon-color" :style="fill"></help-icon>
      </div>
    </b-button>

    <b-dropdown-item aria-role="menu-item" :has-link="true" v-if="liveChatVisible">
      <a @click.prevent="openChatWithCustomerSupport">
        {{ liveChatButtonTitle }}
      </a>
    </b-dropdown-item>
    <b-dropdown-item aria-role="menu-item" :has-link="true">
      <a @click.prevent="openOrganizerEmailModal">
        {{ feedbackButtonTitle }}
      </a>
    </b-dropdown-item>
    <b-dropdown-item aria-role="menu-item" :has-link="true">
      <a :href="$t('navbar.help_faq_url')" target="_blank">
        {{ $t("navbar.help_faq_title") }}
      </a>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import HelpIcon from "@/assets/navbar/help-icon.svg";
import Constants from "@/web/constants";
import { mapActions, mapGetters, mapState } from "vuex";
import NavOrganizerEmailModal from "@/web/components/navbar/NavOrganizerEmailModal";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "NavHelp",
  mixins: [LpConfigMixin],
  components: {
    HelpIcon,
  },

  props: ["fill"],

  computed: {
    ...mapGetters(["helpModule"]),
    ...mapState(["event"]),
    ...mapGetters(["inboxEnabled"]),
    ...mapGetters("auth", ["isAuthorized"]),

    Constants: () => Constants,

    customerSupportUuid() {
      return this.helpModule && this.helpModule[LpConfigConstants.HELP_FIELDS.CUSTOM_SUPPORT_USER_UUID];
    },

    feedbackButtonTitle() {
      return this.getCustomTranslation(this.helpModule, LpConfigConstants.HELP_FIELDS.FEEDBACK_TITLE, this.$t("navbar.help_send_feedback"));
    },

    liveChatButtonTitle() {
      return this.getCustomTranslation(
        this.helpModule,
        LpConfigConstants.HELP_FIELDS.LIVECHAT_TITLE,
        this.$t("navbar.help_customer_support"),
      );
    },

    liveChatVisible() {
      return this.customerSupportUuid && this.inboxEnabled;
    },
  },

  methods: {
    ...mapActions("sideMenu", ["openInboxBubbleWithThread", "openInboxBubbleWithUser"]),
    ...mapActions("inboxThreads", ["checkIfThreadExists"]),

    openChatWithCustomerSupport() {
      if (this.isAuthorized) {
        this.checkIfThreadExists({ userId: this.customerSupportUuid }).then(result => {
          if (result.data) {
            const threadId = result.data.id;
            this.openInboxBubbleWithThread(threadId);
          } else {
            this.openInboxBubbleWithUser(this.customerSupportUuid);
          }
        });
      } else {
        this.$root.openLoginModal();
      }
    },

    openOrganizerEmailModal() {
      this.$buefy.modal.open({
        parent: this,
        component: NavOrganizerEmailModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.button:active,
.button.is-active {
  .navbar-icon-color {
    fill: #ffffff;
  }
}

.navbar-icon-color {
  fill: #848484;
}
</style>
