<template>
  <div class="read-more-text">
    <div v-if="open || !shouldTruncate">
      <div>
        {{ text }}
      </div>
      <a class="read-more-button" @click="toggle" v-if="shouldTruncate">
        {{ $t("common.read_less_text") }}
      </a>
    </div>
    <div v-else>
      <div>
        {{ truncatedText }}
      </div>
      <a class="read-more-button" @click="toggle">
        {{ $t("common.read_more_text") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReadMoreText",

  props: {
    text: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  computed: {
    maxLines: () => 5,

    shouldTruncate() {
      return this.text.length > 200 || this.linesOfText.length > this.maxLines;
    },

    truncatedText() {
      const linesOfText = this.linesOfText;
      if (linesOfText.length > this.maxLines) {
        let count = 0;
        let index = 0;
        while (count < 200 && index < this.maxLines) {
          //+1 is for the newline char
          count += linesOfText[index].length + 1;
          index++;
        }
        return `${this.text.substring(0, Math.min(count, 200))}...`;
      } else {
        return `${this.text.substring(0, 200)}...`;
      }
    },

    linesOfText() {
      return this.text.split(/\r\n|\r|\n/);
    }
  },

  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
.read-more-button {
  cursor: pointer;
}
</style>
