<template>
  <dropdown-select
    :items="allQuestionsFilterTypes"
    :multiple="false"
    :value="currentValue"
    radius="0px"
    :border-left="false"
    :border-right="false"
    :border-top="false"
    @input="selectQuestionsFilterType"
  >
    <!-- Path tag items -->
    <template #dropdown="{ item }">
      <div class="mr-1 filter-text">
        <span v-if="item === 0">{{ $t("sort.sort_by_most_recent") }}</span>
        <span v-else-if="item === 1">{{ $t("sort.sort_by_likes") }}</span>
        <span v-else-if="item === 2">{{ $t("sort.session_questions_sort_by_moderation_recent") }}</span>
        <span v-else-if="item === 3">{{ $t("sort.session_questions_sort_by_moderation_oldest") }}</span>
      </div>
    </template>

    <!--Button icon-->
    <template #icon>
      <div class="filter-icon-container">
        <filter-icon class="svg-icon"></filter-icon>
      </div>
    </template>
  </dropdown-select>
</template>

<script>
import DropdownSelect from "@/shared/components/DropdownSelect";
import {mapGetters, mapMutations, mapState} from "vuex";
import FilterIcon from "@/assets/icon_filter.svg";

export default {
  name: "QuestionsFilterTypeSelect",

  components: {DropdownSelect, FilterIcon},

  data() {
    return {
      currentValue: 0,
    };
  },

  methods: {
    ...mapMutations("agendaSessionQuestions", ["selectQuestionsFilterType"]),
  },

  computed: {
    ...mapGetters("agendaSessionQuestions", ["allQuestionsFilterTypes"]),
    ...mapState("agendaSessionQuestions", ["selectedQuestionsFilterType"]),
  },

  watch: {
    selectedQuestionsFilterType: {
      immediate: true,
      handler: function (newValue) {
        this.currentValue = newValue;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.filter-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.svg-icon {
  fill: var(--primary-color);
}

.filter-text {
  font-size: 16px;
  line-height: 130%;
}
</style>
