<template>
  <div>
    <div class="box">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.user_picker_title") }}</div>
      <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
        <b-button type="is-primary" class="is-small mr-1" @click="finishWithSelection">
          {{ $t("admin_panel.select") }}
        </b-button>
        <b-button type="is-primary" class="is-small mr-1" @click="finishWithoutSelection">{{ $t("admin_panel.exit") }}
        </b-button>
      </div>
      <search-input :is-small="true" :button-visible="false" v-model="searchQuery" class="mb-2"></search-input>
      <div v-for="user in users" :key="user.user_identity_token">
        <div class="columns is-mobile is-vcentered is-variable is-1">
          <div class="column is-narrow">
            <checkbox
              :value="(selectedUser && selectedUser.user_identity_token) === user.user_identity_token"
              @input="toggleSelection($event, user)"
            ></checkbox>
          </div>
          <div class="column">
            <div class="is-flex is-flex-direction-row">
              <profile-picture
                class="is-36x36"
                :picture="user.picture"
                :initials="userInitials(user)"
              ></profile-picture>
              <div class="ml-1">{{ getUserFullName(user) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { fullName, initials } from "@/shared/utils";
import SearchInput from "@/shared/components/SearchInput";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import Checkbox from "@/shared/components/form/Checkbox";

export default {
  name: "AdminPanelUserPicker",
  components: { SearchInput, ProfilePicture, Checkbox },
  data() {
    return {
      selectedUser: null,
      searchQuery: null,
    };
  },

  beforeDestroy() {
    this.clearSearchResult();
  },

  computed: {
    ...mapState("attendees", ["searchResult"]),

    users() {
      return this.searchResult || [];
    },

    selectedUserFullName() {
      return this.selectedUser && fullName(this.selectedUser);
    },
  },

  methods: {
    ...mapActions("attendees", ["searchAttendees"]),
    ...mapMutations("attendees", ["clearSearchResult"]),

    toggleSelection(isSelected, user) {
      this.selectedUser = user;
    },

    getUserFullName(user) {
      return fullName(user);
    },

    finishWithSelection() {
      this.$emit("user-uuid-selected");
      this.$root.$emit("user-uuid-selected", this.selectedUser.user_identity_token);
    },

    finishWithoutSelection() {
      this.$emit("user-uuid-selected");
    },

    userInitials(user) {
      return initials(user);
    },
  },

  watch: {
    searchQuery: function(newVal) {
      if (newVal.length) {
        this.searchAttendees(newVal);
      } else {
        this.clearSearchResult();
      }
    },
  },
};
</script>

<style scoped></style>
