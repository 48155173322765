<template>
  <div ref="notificationListElement" class="notification-list" v-prevent-parent-scroll>
    <template v-if="notifications && notifications.length">
      <div @click="onNotificationClick(notification)" v-for="notification in notifications" :key="notification.id">
        <notification :notification="notification"></notification>
      </div>
      <list-loading-indicator class="list-loading-indicator" :active="isLoading"></list-loading-indicator>
    </template>
    <div v-else-if="showEmptyPlaceholder" class="has-text-centered has-text-secondary mt-3">
      <div>
        {{ $t("notifications.empty_placeholder") }}
      </div>
    </div>
    <list-loading-indicator v-else class="list-loading-indicator" :active="isLoading"></list-loading-indicator>
  </div>
</template>

<script>
import Notification from "@/web/components/notifications/Notification";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import { mapActions, mapGetters } from "vuex";
import Constants from "@/web/constants";
import { throttle } from "@/shared/utils";
import {
  isExhibitorComponent,
  isFeedWallComponent,
  isQuizzesComponent,
  isBusinessMatchingComponent,
  hasBusinessMatchingAcceptedTab,
  hasBusinessMatchingPendingTab,
  hasBusinessMatchingMeetingVideoCallRoom,
  getBusinessMatchingMeetingId,
  getComponentId,
  getExhibitorId,
  getQuizId,
  getFeedWallChannelId,
  getFeedWallThreadId,
} from "@/shared/utils/deeplink-utils";

export default {
  name: "NavNotifications",

  components: {
    Notification,
    ListLoadingIndicator,
  },

  data() {
    return {
      scrollListener: null,
    };
  },

  computed: {
    ...mapGetters("notifications", { notifications: "getNotifications", isLoading: "isLoading" }),

    scrollThreshold: () => 300,

    showEmptyPlaceholder() {
      return !this.isLoading && this.notifications && !this.notifications.length;
    },
  },

  mounted() {
    this.loadNotificationsPage();
    this.addScrollListener();
  },

  beforeDestroy() {
    this.removeScrollListener();
  },

  methods: {
    ...mapActions("notifications", ["loadNotificationsPage", "markNotificationAsRead"]),
    ...mapActions("sideMenu", ["openFeedWallBubble", "openQuizBubble"]),

    addScrollListener() {
      if (this.$refs.notificationListElement && !this.scrollListener) {
        this.scrollListener = ev => {
          const threadList = ev.target;
          let isOnBottomOfList = threadList.scrollTop > threadList.scrollHeight - threadList.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs.notificationListElement.addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.scrollListener) {
        this.$refs.notificationListElement.removeEventListener("scroll", this.scrollListener);
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.loadNotificationsPage();
    }, 1000),

    onNotificationClick(notification) {
      if (!notification.read_at) {
        this.markNotificationAsRead(notification.id);
      }
      const notificationType = notification.notify_type;
      const notificationSubtype = notification.notify_subtype;

      if (notificationType === Constants.NOTIFICATION_TYPE.FRIENDS) {
        this.$router.push({name: Constants.ROUTE_FRIENDS_INVITATIONS});
      } else if (notificationType === Constants.NOTIFICATION_TYPE.BUSINESS_MATCHING) {
        if (!this.handleDeepLink(notification.dl)) {
          this.$root.openNotificationModal(notification);
        }
      } else if (notificationType === Constants.NOTIFICATION_TYPE.EVENT) {
        if (notificationSubtype === Constants.NOTIFICATION_SUBTYPE.EVENT_STANDARD) {
          if (!this.handleDeepLink(notification.dl)) {
            this.$root.openNotificationModal(notification);
          }
        }
      } else if (notificationType === Constants.NOTIFICATION_TYPE.FEED_WALL) {
        if (!this.handleDeepLink(notification.dl)) {
          this.$root.openNotificationModal(notification);
        }
      } else {
        this.$root.openNotificationModal(notification);
      }
      this.$emit("toggle-dropdown");
    },

    handleDeepLink(deepLink) {
      if (deepLink) {
        if (isExhibitorComponent(deepLink)) {
          const componentId = getComponentId(deepLink);
          const exhibitorId = getExhibitorId(deepLink);
          this.$router.push({name: Constants.ROUTE_EXHIBITOR, params: {componentId, exhibitorId}});
          return true;
        } else if (isQuizzesComponent(deepLink)) {
          const componentId = getComponentId(deepLink);
          const quizId = getQuizId(deepLink);
          // this.$router.push({name: Constants.ROUTE_QUIZ_QUESTIONS, params: {componentId, quizId}});
          this.openQuizBubble(quizId);
          return true;
        } else if (isFeedWallComponent(deepLink)) {
          const componentId = getComponentId(deepLink);
          const feedWallChannelId = getFeedWallChannelId(deepLink);
          const feedWallThreadId = getFeedWallThreadId(deepLink);
          this.openFeedWallBubble({feedWallChannelId: feedWallChannelId, feedWallThreadId: feedWallThreadId});
          return true;
        } else if (isBusinessMatchingComponent(deepLink)) {
          if (hasBusinessMatchingMeetingVideoCallRoom(deepLink)) {
            this.openJitsiCallFromDeeplink(deepLink);
            return true;
          } else if (hasBusinessMatchingAcceptedTab(deepLink)) {
            const meetingId = getBusinessMatchingMeetingId(deepLink);
            this.$router.push({name: Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED, params: {meetingId: meetingId}});
            return true;
          } else if (hasBusinessMatchingPendingTab(deepLink)) {
            const meetingId = getBusinessMatchingMeetingId(deepLink);
            this.$router.push({name: Constants.ROUTE_BUSINESS_MATCHING_PENDING, params: {meetingId: meetingId}});
            return true;
          }
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.notification-list {
  overflow-y: auto;
  max-height: 488px;
  min-width: 300px;
  min-height: 80px;
  overflow-x: hidden;
}

.list-loading-indicator {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
